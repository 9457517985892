<template>
  <div id="DivAgendamentoEntrega">
    <div class="col-12">
      <div class="card mb-4">

        <div id="LogoVapza">
        
        <b-row>
           
        <b-col md="12">
        <dark-Toggler class="d-soon d-lg-block"  style="text-align: right;"  />
          <b-img
              :src="appLogoImage"
              alt="logo"
              width="100%"
              style="margin: 1%;"
            />
           </b-col>
          </b-row>
        
        
      </div>

        <div class="card-header">
          <h4 class="card-title">
            <span>Agendamentos de Entregas</span>
          </h4>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group label="Numero Pedido">
                <b-form-input
                    id="NUNOTA"
                    v-model="agendamento.NUNOTA"
                    v-maska="'###########'"
                    disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="CNPJ">
                <b-form-input
                    v-model="agendamento.CNPJ"
                    v-maska="maskCNPJ"
                    placeholder="00.000.000/0000-00"
                    disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Status Interno">
                <b-form-select
                    id="STATUSINT"
                    v-model="agendamento.STATUSINT"
                    :options="optionsStatusInterno"
                    :disabled="!edicaoAdm"
                />
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <b-col md="2">
              <b-form-group label="Cód. Parceiro">
                <b-form-input
                    id="CODPARC"
                    v-model="agendamento.CODPARC"
                    disabled
                />
              </b-form-group>
            </b-col>


            <b-col md="10">
              <b-form-group label="Parceiro">
                <b-form-input
                    id="RAZAOSOCIAL"
                    v-model="agendamento.RAZAOSOCIAL"
                    disabled
                />
              </b-form-group>
            </b-col>

          </b-row>
          
          <br>

          

          <b-table
              :items="produtos"
              :fields="fieldsProdutos"
              responsive="sm"
              striped
              sticky-header
              show-empty
          >
            <template #cell(DESCRPROD)="row">
              {{ row.value }}
            </template>

            <template #cell(DESCRLOCAL)="row">
              {{ row.value }}
            </template>
          </b-table>

          <br>

          <b-row>
            <b-col mb="2">
              <b-form-group label="Peso Bruto" > 
                <b-form-input
                    v-model="agendamento.PESOBRUTO"
                    v-maska="maskPESOBRUTO"
                    min="1"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col mb="2">
              <b-form-group label="Volume Caixas">
                <b-form-input
                    v-model="agendamento.VOLUMECXS"
                    v-maska="maskVOLUMECXS"
                    min="1"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col mb="2">
              <b-form-group label="Volume Pallets">
                <b-form-input
                    v-model="agendamento.VOLUMEPALLETS"
                    v-maska="maskVOLUMEPALLETS"
                    min="1"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col md="4">
              <b-form-group label="Tipo de Veiculo">
                <b-form-select
                    v-model="agendamento.CODTPVEIC"
                    :options="optionsVeiculos"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Email Transportadora">
                <b-form-input
                    v-model="agendamento.EMAILCTT"
                    placeholder="meu_email@exemplo.com"
                    type="email"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Telefone Fornecedor">
                <b-form-input
                    v-model="agendamento.TELEFONEFORN"
                    v-maska="maskTelefone"
                    :disabled="edicaoAdm"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col md="3">
              <b-form-group label="Data para Entrega">
                <b-form-datepicker
                    id="DTENTR"
                    v-model="agendamento.DTENTR"
                    today-button
                    label-today-button="Selecionar Hoje"
                    reset-button
                    label-reset-button="Limpar"
                    placeholder="Selecione uma Data"
                    :required="true"
                    dropup
                    :min="dataMin()"
                    :date-disabled-fn="apenasDiasUteis"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Hora para Entrega">
                <b-form-select
                    v-model="agendamento.HORA"
                    :options="optionsHorarios"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Anexos">
                <b-form-file
                    id="anexos"
                    v-model="anexos"
                    multiple
                    accept="text/xml, application/pdf"
                    placeholder="Nenhum arquivo anexado"
                    drop-placeholder="Solte o arquivo aqui"
                    browse-text="Escolher"
                >
                  <template
                      slot="file-name"
                      slot-scope="{ names }"
                  >
                    <b-badge
                        v-for="name in names"
                        :key="name"
                        variant="dark"
                    >
                      {{ name }}
                    </b-badge>
                  </template>
                </b-form-file>
              </b-form-group>
            </b-col>

            <div class="DivBotaoLadoLado">
              <b-button class="mt-18 DivBotaoLadoLado" size="sm" @click="baixarAnexos" variant="primary">Baixar</b-button>
          </div>

          <div class="DivBotaoLadoLado" v-if="PermiteSalvar">
            <b-button
                    class="mt-18 DivBotaoLadoLado"
                    size="sm"
                    variant="info"
                    @click="limpaAnexos()"
                >Limpar
                </b-button>
          </div>

          </b-row>

          
          <br>

          <div class="mt-1">
            <b-form-group label="Observação">
              <b-form-textarea
                  id="textarea"
                  v-model="agendamento.OBSERVACAO"
                  rows="3"
                  max-rows="6"
              />
            </b-form-group>
          </div>

          <div
              v-if="edicaoAdm"
              class="DivBotaoLadoLado"
          >
            <b-button
                v-b-toggle="'collapse-filtro'"
                class="mt-3"
                variant="danger"
                @click="$router.push({ name: 'agendamentoEntregaLista' })"
            >
              Retornar
            </b-button>
          </div>

          <div class="DivBotaoLadoLado" v-if="PermiteSalvar">
            <b-button
                v-b-toggle="'collapse-filtro'"
                class="mt-3"
                variant="primary"
                @click="submit()"
            >
              Salvar
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="edicaoAdm">
    <div class="card mb-4">
      <div class="card-header">
          <h4 class="card-title">
            <span>XMLs no Sankhya</span>
          </h4>
        </div>
        <div class="card-body">


          <div>
            <b-table
              :items="AD_TGFIXNXPED"
              :fields="AD_TGFIXNXPEDFields"
              responsive="md"
              striped
              sticky-header
            >
              

              <template #cell(BAIXAR)="row">
                <b-button
                  
                  size="sm"
                  variant="outline-primary"
                  @click="baixarXMLs(row.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="12"
                  />
                </b-button>
              </template>
            </b-table>
          </div>


        </div>
    </div>
  </div>


  </div>


  


</template>
<script>

import 'bootstrap-icons/font/bootstrap-icons.css'
import {
  BBadge,
  BButton,
  BCol,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BTable,
  VBToggle,
  BImg,
} from 'bootstrap-vue'
import { maska } from 'maska'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import apiPortalSky from '@/services/apiPortalSky'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BBadge,
    DarkToggler,
    BImg,
  },
  directives: {
    'b-toggle': VBToggle,
    maska,
  },
  props: ['nunota', 'edicaoAdm'],
  data() {
    return {
      appLogoImage:'',
      // MASCARAS
      maskCNPJ: '##.###.###/####-##',
      maskTelefone: ['+55 (##) ####-####', '+55 (##) # ####-####'],
      maskPESOBRUTO: ['##', '##,##', '###', '###,##', '#.###,##', '##.###,##', '###.###,##'],
      maskVOLUMECXS: ['###', '#.###', '##.###', '###.###'],
      maskVOLUMEPALLETS: ['###', '#.###', '##.###', '###.###'],

      // TABELAS
      fieldsProdutos: [
        {
          key: 'DESCRPROD',
          label: 'Produtos Vapza',
          sortable: true,
          tdClass: 'td100',
        },
        {
          key: 'DESCRLOCAL',
          label: 'Setor/Armazenamento',
          sortable: true,
          tdClass: 'td100',
        },
      ],
      produtos: [{
        CODPROD: null,
        AD_CODESTRAPOLO: '',
        DESCRPROD: '',
        DESCRLOCAL: '',
        CGC_CPF: null,
        DTPREVENT: null,
        PESOBRUTO: null,
        CODVOL: '',
        QTDNEG: null,
      }],

      // LISTAS
      optionsVeiculos: [
        {
          value: null,
          text: 'Selecione uma opção',
          disabled: true,
        },
      ],
      optionsHorarios: [

        {
          value: null,
          text: 'Selecione uma opção',
          disabled: true,
        },
      ],
      optionsStatusInterno: [
        {
          value: 'CONFERIR',
          text: 'CONFERIR',
          disabled: false,
        },
        {
          value: 'CONFERIDO',
          text: 'CONFERIDO',
          disabled: false,
        },
        {
          value: 'DIVERGENTE',
          text: 'DIVERGENTE',
          disabled: false,
        },
      ],
      anexos: null,

      // VARIAVEIS
      agendamento: {
        CODAGE: 0,
        STATUSINT: 'CONFERIR',
        NUNOTA: 0,
        CNPJ: null,
        DTENTR: null,
        HORA: null,
        CODLOCAL: null,
        PESOBRUTO: null,
        VOLUMECXS: 0,
        VOLUMEPALLETS: 0,
        CODTPVEIC: null,
        EMAILCTT: null,
        TELEFONEFORN: null,
        OBSERVACAO: '',
        DHCAD: new Date(),
        ANEPDF: null,
        ANEXML: null,
        NOMEANEXML: null,
        NOMEANEPDF: null,
        DTPROR: null,
        HORAPROR: null,
      },
      jaProrrogado: false,
      PermiteSalvar: true,


      AD_TGFIXNXPED: [],
      AD_TGFIXNXPEDFields: [
        { 
          key: 'BAIXAR',
          label: 'Baixar',
          sortable: false,
          tdClass: 'td5',
        },
        {
          key: 'NUMNOTA',
          label: 'Nro NOTA',
          sortable: false,
          tdClass: 'td100',
        },
        {
          key: 'CHAVEACESSO',
          label: 'Chave de Acesso',
          sortable: false,
          tdClass: 'td350',
        },
       
      ],
    }
  },
  mounted() {
    this.appLogoImage = require('@/assets/images/logo/LogoVapza.png')

    if (this.nunota) {
      this.setNUNOTA(this.nunota)
      this.buscarTipoVeiculos()
      this.buscarPedido()
    }else{
      AlertaMsg.FormataMsg({
              title: 'Atenção!',
              alert_type: 'attention',
              message: 'Problema ao tentar localizar o Pedido.<br> Por favor entre em contato com o Comprador.',
              })
      this.$router.push({ name: 'MensagemOut' })
    }
  },
  methods: {

    // MÉTODOS DE SETTERS -------------------------------------------------------------------------

    setNUNOTA(newValue) {
      this.agendamento.NUNOTA = Number(newValue)
    },

    setVOLUMECXS(newValue) {
      this.agendamento.VOLUMECXS = Number(newValue.toString()
        .replace('.', '')
        .replace(',', '.'))
    },

    setVOLUMEPALLETS(newValue) {
      this.agendamento.VOLUMEPALLETS = Number(newValue.toString()
        .replace('.', '')
        .replace(',', '.'))
    },

    setCNPJ(newValue) {
      this.agendamento.CNPJ = this.formataCnpj(newValue)
    },

    setPESOBRUTO(newValue) {
      this.agendamento.PESOBRUTO = Number(newValue.toString()
        .replace('.', '')
        .replace(',', '.'))
    },

    // MÉTODOS DE REQUISIÇÕES ---------------------------------------------------------------------

    async submit() {

      try{

          Spinner.StarLoad()
          if(this.edicaoAdm == true){
            if (this.agendamentoIsValido()){
            await this.montaAgendamento()
            await this.inserirAgendamento()
            }
          } else{
      
            
            if (!this.jaProrrogado) {
              if (this.agendamentoIsValido()) {
                
                this.Validacao = await this.validarDataHoraAgendamento()
                console.error(this.Validacao)

                if(this.Validacao === '')  {
                  await this.montaAgendamento()
                  await this.inserirAgendamento()
                }else{
                  AlertaMsg.FormataMsg({
                      title: 'Atenção!',
                      alert_type: 'attention',
                      message: this.Validacao,
                    })
                }
              }
            } else {
              AlertaMsg.FormataMsg({
                      title: 'Atenção!',
                      alert_type: 'attention',
                      message: 'Não é possível prorrogar o agendamento mais de uma vez!',
                    })
            }
          }

  } catch (error) {
          AlertaMsg.FormataMsg({
             alert_type: 'error',
             message: error,
           });
        }
    },

    async inserirAgendamento() {
    
      const usuario = this.$session.get('usuario')
      this.agendamento.CODUSU =  usuario.codusu //Para Gravar Logs
      
      const URL = this.agendamento.CODAGE && this.agendamento.CODAGE !== 0 ? '/AgendamentoEntrega/Alterar' : '/AgendamentoEntrega/Inserir'
      
      await apiPortalSky.post(URL, JSON.parse(JSON.stringify(this.agendamento)))
        .then(response => {
          if (response.status === 200) {
            this.PermiteSalvar = false
            AlertaMsg.FormataMsg({
              title: 'Sucesso!',
              alert_type: 'info',
              message: this.agendamento.CODAGE && this.agendamento.CODAGE !== 0 ? 'Alterado com sucesso' : 'Salvo com sucesso',
            })
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: this.agendamento.CODAGE && this.agendamento.CODAGE !== 0 ? 'Erro ao alterar' : 'Erro ao salvar'+error,
          })
        })
    },

    async validarDataHoraAgendamento() {
      const DATA = this.agendamento.DTPROR ? this.agendamento.DTPROR : this.agendamento.DTENTR;
      const HORA = this.agendamento.HORAPROR ? this.agendamento.HORAPROR : this.agendamento.HORA;
      
      const URL = `/AgendamentoEntrega/ValidarDataHora?NUNOTA=${this.agendamento.NUNOTA}&data=${DATA}&hora=${HORA}&CODLOCAL=${this.agendamento.CODLOCAL}`;
    
      try {
        const response = await apiPortalSky.post(URL);
        
        if (response.status === 200) {
          return ""; // Retorna vazio se a validação passar
        }
    
      } catch (error) {
        console.error(error);
        
      }
    
      return "Já existe um agendamento nesta mesma <b>Data e Hora</b>, por favor selecione outras opções";
    },

    async isEdicao() {
      if (this.agendamento.NUNOTA) {        
        this.jaProrrogado = false;//this.agendamento.DTPROR || this.agendamento.HORAPROR ? true : false;
        this.agendamento.DTENTR = (this.agendamento.DTPROR) ? this.agendamento.DTPROR: this.agendamento.DTENTR;
        this.agendamento.HORA = (this.agendamento.HORAPROR) ? this.agendamento.HORAPROR: this.agendamento.HORA;
      }
    },
    async buscarPedido() {
      Spinner.StarLoad()
      
      const URL = `/AgendamentoEntrega/Mostra?nunota=${this.agendamento.NUNOTA}`
      await apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200) {

            this.limpaPedido()
            this.limpaProdutos()
            this.limpaAnexos()

            this.produtos = response.data
            
            
           //Armazena Dados do Agendamento
           this.agendamento = this.produtos[0]
           this.converterEmArquivo()
           this.isEdicao()
           
            this.buscarHorarios(this.produtos[0].CODLOCAL)
            
            this.setCNPJ(this.produtos[0].CGC_CPF)
            this.setPESOBRUTO(this.produtos[0].PESOBRUTO)
            
            this.buscarXMLs(this.produtos[0].NUNOTA)

            if(this.edicaoAdm == true || this.produtos[0].STATUSINT != 'CONFERIDO'){
              this.PermiteSalvar = true
            }else{
              this.PermiteSalvar = false
            }

            if(this.produtos[0].RESTRICOES != "" && this.edicaoAdm != true){

              AlertaMsg.FormataMsg({
              title: 'Atenção!',
              alert_type: 'attention',
              message: this.produtos[0].RESTRICOES,
              })

              // Encaminha usuario para outra tela quando houver erro
              this.edicaoAdm ? this.$router.push({ name: 'agendamentoLista' }) : this.$router.push({ name: 'error' })


            }else{
              Spinner.EndLoad()
            }

          } else {
            AlertaMsg.FormataMsg({
              title: 'Atenção!',
              alert_type: 'attention',
              message: 'Pedido não Localizado',
            })

            // Encaminha usuario para outra tela quando houver erro
            this.edicaoAdm ? this.$router.push({ name: 'agendamentoLista' }) : this.$router.push({ name: 'error' })
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar dados do pedido',
          })

           // Encaminha usuario para outra tela quando houver erro
           this.edicaoAdm ? this.$router.push({ name: 'agendamentoLista' }) : this.$router.push({ name: 'error' })

        })
    },

    async buscarTipoVeiculos() {
      const URL = '/TipoVeiculo/Buscar'

      await apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200) this.mapearOptionsVeiculos(response.data)
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar tipos de veículos',
          })
        })
    },
    async buscarHorarios(CODLOCAL) {

      const URL = `/LocalArmazenagem/BuscarHorario?CODLOCAL=${CODLOCAL}`

      await apiPortalSky.post(URL)
        .then(response => {
          if (response.status === 200){

            this.mapearOptionsHorarios(response.data)
          }
        })
        .catch(error => {
          console.error(error)
          AlertaMsg.FormataMsg({
            alert_type: 'error',
            message: 'Erro ao buscar horarios',
          })
        })
    },
    async buscarXMLs(NUNOTA) {

    const URL = `/AgendamentoEntrega/BuscarXMLs?nunota=${NUNOTA}`
    this.AD_TGFIXNXPED = []

    await apiPortalSky.post(URL)
      .then(response => {
        if (response.status === 200){
    
          this.AD_TGFIXNXPED = response.data
        }
      })
      .catch(error => {
        console.error(error)
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Erro ao buscar os XMLs',
        })
      })
    },
    baixarAnexos() {
      if (this.anexos.length === 0) {
        alert('Nenhum arquivo anexado para baixar.');
        return;
      }

      // Itera sobre cada arquivo selecionado
      this.anexos.forEach(file => {

         if(file.type == 'text/xml'){
           UtilVUE.DownloadFile(this.agendamento.ANEXML, file.name);
          
        }
        if(file.type == 'application/pdf'){
          UtilVUE.DownloadFile(this.agendamento.ANEPDF, file.name); 
        }
      });
    },

    baixarXMLs(ARQUIVO) {
      UtilVUE.DownloadArquivosXML_TXT(ARQUIVO.XML,ARQUIVO.NUMNOTA,"XML");

    },

    // MÉTODOS DE VALIDAÇÕES ----------------------------------------------------------------------

    agendamentoIsValido() {

      if (!this.agendamento.CNPJ) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'CNPJ obrigatório',
        })
        return false
      }

      if (!this.agendamento.PESOBRUTO || this.agendamento.PESOBRUTO < 1) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Peso Bruto incorreto',
        })
        return false
      }

      if (!this.agendamento.VOLUMECXS || this.agendamento.VOLUMECXS < 1) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Volume de Caixas incorreto',
        })
        return false
      }

      
      if (!this.agendamento.CODTPVEIC) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Tipo de Veiculo obrigatório',
        })
        return false
      }

      if (!this.agendamento.EMAILCTT) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Email Transportadora obrigatório',
        })
        return false
      }

      if (!new RegExp(/^[^@]+@\w+(\.\w+)+\w$/).test(this.agendamento.EMAILCTT)) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Email não é válido',
        })
        return false
      }

      if (!this.agendamento.TELEFONEFORN) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Telefone Fornecedor obrigatório',
        })
        return false
      }

      if (!this.agendamento.DTENTR) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Data para Entrega obrigatório',
        })
        return false
      }

      if (!this.agendamento.HORA) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Hora para Entrega obrigatório',
        })
        return false
      }

      /*
      if (!this.anexos) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Anexo obrigatório',
        })
        return false
      }

      if (!this.anexos.find(file => file.type === 'text/xml')) {
        AlertaMsg.FormataMsg({
          alert_type: 'error',
          message: 'Obrigatório 1 anexo <b>XML</b>',
        })
        return false
      }*/

      return true
    },

    // MÉTODOS -----------------------------------------------------------------------------------------

    formataCnpj(newValue) {
      if (newValue) {
        const cnpj = newValue.replace(/\D/g, '')
        if (cnpj.length === 14) {
          return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
        }
      }
      return newValue
    },

    mapearOptionsVeiculos(options) {
      for (const opt of options) {
        this.optionsVeiculos.push({
          disabled: false,
          value: opt.CODTPVEIC,
          text: opt.DESCR,
        })
      }
    },

    mapearOptionsHorarios(options) {
      for (const opt of options) {
        this.optionsHorarios.push({
          disabled: false,
          value: opt.ID,
          text: opt.DESCRICAO,
        })
      }
    },

    /**
     * Desabilita finais de semana
     * @param ymd
     * @param data
     */
    apenasDiasUteis(ymd, data) {
      
      const diaCalendario = data.getDate();
      const diaDaSemana = data.getDay();
      const ultimoDia = new Date(data.getFullYear(), data.getMonth() + 1, 0).getDate();

      
      if(this.edicaoAdm == false){
         return diaDaSemana === 0 || diaDaSemana === 6 || ultimoDia === diaCalendario
      } else {
        return false
      }
    },

    dataMin() {
      const data = new Date()

      if(this.edicaoAdm != true){
        data.setDate(data.getDate() + 1)
      }
      
      return data
    },

    async converterEmBase64() {
       const promises = this.anexos.map(file => {
         return new Promise((resolve, reject) => {
           const reader = new FileReader();
     
           reader.onload = function () {
             resolve({ 
               base64: reader.result.replace('data:', '').replace(/^.+,/, ''), 
               name: file.name, 
               type: file.type 
             });
           };
     
           reader.onerror = function (error) {
             reject(error);
           };
     
           reader.readAsDataURL(file);
         });
       });
     
       try {
         const arquivosConvertidos = await Promise.all(promises);
     
         arquivosConvertidos.forEach(({ base64, name, type }) => {
           if (type === 'text/xml') {
             this.agendamento.NOMEANEXML = name;
             this.agendamento.ANEXML = base64;
           } else if (type === 'application/pdf') {
             this.agendamento.NOMEANEPDF = name;
             this.agendamento.ANEPDF = base64;
           }
         });
     
       } catch (error) {
         console.error(error);
         AlertaMsg.FormataMsg({
           alert_type: 'error',
           message: 'Erro ao converter anexo',
         });
       }
    },

    converterEmArquivo() {
      const container = new DataTransfer()
      if (this.agendamento.ANEXML) {
        container.items.add(new File([this.agendamento.ANEXML], this.agendamento.NOMEANEXML, {
          type: 'text/xml',
          lastModified: new Date(),
        }))
      }

      if (this.agendamento.ANEPDF && this.agendamento.ANEPDF !== '') {
        container.items.add(new File([this.agendamento.ANEPDF], this.agendamento.NOMEANEPDF, {
          type: 'application/pdf',
          lastModified: new Date(),
        }))
      }

      const anexos = document.getElementById('anexos')
      anexos.files = container.files
      anexos.dispatchEvent(new Event('change', { bubbles: true }))
    },

    limpaPedido() {
      this.agendamento = {
        CODAGE: 0,
        STATUSAGEN: 'AGENDADO',
        STATUSINT: 'CONFERIR',
        NUNOTA: this.agendamento.NUNOTA,
        CNPJ: null,
        DTENTR: null,
        HORA: null,
        PESOBRUTO: null,
        VOLUMECXS: 0,
        VOLUMEPALLETS: 0,
        CODTPVEIC: null,
        EMAILCTT: null,
        TELEFONEFORN: null,
        OBSERVACAO: '',
        DHCAD: new Date(),
        ANEPDF: null,
        ANEXML: null,
        NOMEANEXML: null,
        NOMEANEPDF: null,
        DTPROR: null,
        HORAPROR: null,
      }
    },

    limpaProdutos() {
      this.produtos = [{
        CODPROD: null,
        AD_CODESTRAPOLO: '',
        DESCRPROD: '',
        DESCRLOCAL: '',
        CGC_CPF: null,
        DTPREVENT: null,
        PESOBRUTO: null,
        CODVOL: '',
        QTDNEG: null,
      }]
    },

    limpaAnexos() {
      this.anexos = null
      this.agendamento.ANEXML = null
      this.agendamento.ANEPDF = null
      this.agendamento.NOMEANEXML = null
      this.agendamento.NOMEANEPDF = null
    },

    async montaAgendamento() {
      
      if (this.anexos) {
        await this.converterEmBase64()
      }

      this.setNUNOTA(this.agendamento.NUNOTA)
      this.setVOLUMECXS(this.agendamento.VOLUMECXS)
      this.setVOLUMEPALLETS(this.agendamento.VOLUMEPALLETS)
      this.setPESOBRUTO(this.agendamento.PESOBRUTO)

      
      
      this.agendamento.VOLUMECXS = Number(this.agendamento.VOLUMECXS.toString())
      this.agendamento.VOLUMEPALLETS = Number(this.agendamento.VOLUMEPALLETS.toString())
      this.agendamento.PESOBRUTO = Number(this.agendamento.PESOBRUTO.toString())     
      this.agendamento.HORA = Number(this.agendamento.HORA.toString())
      this.agendamento.HORAPROR = Number("0")
      
      
    },
  },
}
</script>

<style>
.mt-18 {
  margin-top: 1.8rem;
}
</style>
